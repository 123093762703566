.btn {
  padding: rem(12) rem(16);
  border-radius: rem(8);
  border: none;
  white-space: nowrap;
  &--primary {
    background-color: $color-brand-2-base;
    color: $white;

    &:hover {
      background-color: $color-brand-2-dark-1;
    }

    &:disabled {
      background-color: $color-brand-2-light-1;
    }
  }

  &--secondary {
    background-color: $white;
    color: $color-brand-2-base;
    border: rem(1) solid $color-brand-2-base;

    &:hover {
      background-color: $color-brand-2-light-3;
    }

    &:disabled {
      color: $color-brand-2-light-1;
      border: rem(1) solid $color-brand-2-light-1;
      &:hover {
        background-color: $white;
      }
    }
  }

  mat-icon {
    margin-left: rem(8);
  }
}
