@import "fonts";

html {
  font-family: $roboto;
  font-size: rem(16);
  color: $color-neutral-grey-dark-2;
}

h1 {
  font-size: rem(40);
  font-family: $roboto;
  font-weight: bold;
  color: $color-brand-1-base;
  margin: rem(24) 0;
  line-height: rem(48);
  @include mq($until: tablet) {
    font-size: rem(32);
  }
}
h2 {
  font-size: rem(32);
  font-family: $roboto;
  font-weight: bold;
  color: $color-brand-1-base;
  margin: rem(24) 0;
  line-height: rem(40);
  @include mq($until: tablet) {
    font-size: rem(34);
  }
}
h3 {
  font-size: rem(24);
  line-height: rem(32);
  font-family: $roboto;
  font-weight: bold;
  color: $color-brand-1-base;
  margin: rem(24) 0;
}
h4 {
  font-size: rem(24);
  font-family: $roboto;
  font-weight: bold;
  color: $color-neutral-grey-dark-2;
  margin: rem(16) 0;
  line-height: 1.5em;
}

p,
span {
  font-size: rem(16);
  line-height: rem(24);
}
p {
  margin: rem(16) 0;
  color: $color-neutral-grey-dark-2;
  line-height: rem(24);
}

ul {
  list-style: disc;
}

ul,
ol {
  margin-top: rem(50);
  margin-bottom: rem(50);
  @include mq($until: tablet) {
    margin: 0;
  }
  li {
    margin: rem(16);
    line-height: 1.5em;
  }
}
ol {
  counter-reset: big-counter;
  li {
    counter-increment: big-counter;
    padding-left: rem(8);
    position: relative;
    &:before {
      position: absolute;
      top: rem(-5);
      left: 0;
      content: counter(big-counter) ". ";
      font-family: $roboto;
      font-size: rem(26);
    }
  }
}

a {
  text-decoration: none;
  cursor: pointer;
  color: $color-neutral-grey-dark-2;
  font-family: $roboto;
}

strong, b {
  font-weight: bold;
}

hr {
  height: rem(1);
  background-color: $color-neutral-grey-dark-2;
  margin: rem(40) 0;
}
