html, body {
  height: 100%;
  scroll-behavior: smooth;
  background-color: $white;
  font-family: $roboto;
}

body, .header {
  // max-width: rem(1850);
  margin: 0 auto;
}

.wrapper {
  margin-top: rem(100);
}

body [class^='mat-']:not(mat-icon), body [class*='mat-']:not(mat-icon)  {
  //font-family: $montserrat;
}

:focus {
  outline: 0;
}

.main-container, .small-container, .smaller-container {
  width: 100%;
  margin: 0 auto;
  padding: 0 rem(40);
  @include mq($until: tablet) {
    padding: 0 rem(16);
  }
}
.main-container {
  max-width: 1200px;
}

.small-container {
  max-width: 1080px;
}

.smaller-container {
  max-width: 800px;
}

.full-screen-modal .mat-dialog-container,
.full-screen-modal .mat-dialog-content{
  max-height: 100%;
  max-width: 100%;
  width: 100%;
  height: 100%;
  margin: 0;
}

.full-screen-modal .mat-dialog-content{
  padding-left: 0;
  padding-right: 0;
}

// TAB
mat-tab-group {
  width: 100%;
  //.mat-tab-header-pagination-controls-enabled .mat-tab-header-pagination {
  //  display: none;
  //}
  .mat-tab-header {
    @include mq($until: tablet) {
      margin: 0 rem(-16);
    }
  }
  .mat-tab-label-container {
    .mat-tab-labels {
      justify-content: center;
      color: $color-brand-1-base;
      .mat-tab-label {
        width: 100%;
        opacity: 1;
        border-bottom: rem(1) solid $color-neutral-grey-base;
        .mat-tab-label-content {
          font-size: rem(16);
          line-height: rem(24);
        }
      }
      .mat-tab-label-active {
        opacity: 1;
        border-bottom: rem(2) solid $color-brand-2-base;
        .mat-tab-label-content {
          color: $color-brand-2-base;
        }
      }
    }
  }
  .mat-tab-body-wrapper {
    margin-top: rem(24);
    @include mq($until: tablet) {
      margin-top: rem(16);
    }
  }
}

