$white: #ffffff;
$black: #000000;
$color-brand-1-base: #22418c;
$color-brand-1-dark-1: #1b3470;
$color-brand-1-dark-2: #142754;
$color-brand-1-dark-3: #0d1a38;
$color-brand-1-light-1: #4e67a3;
$color-brand-1-light-2: #a6b2d0;
$color-brand-1-light-3: #e8ecf3;
$color-brand-2-base: #f38238;
$color-brand-2-dark-1: #c2682c;
$color-brand-2-dark-2: #914e21;
$color-brand-2-dark-3: #482610;
$color-brand-2-light-1: #f59b60;
$color-brand-2-light-2: #facdaf;
$color-brand-2-light-3: #fde6d7;
$color-neutral-grey-base: #cacaca;
$color-neutral-grey-dark-3: #505050;
$color-neutral-grey-dark-2: #797979;
$color-neutral-grey-dark-1: #a1a1a1;
$color-neutral-grey-light-1: #dfdfdf;
$color-neutral-grey-light-2: #f4f4f4;
$color-neutral-grey-light-3: #f9f9f9;
$color-accent-positive-base: #6bd284;
$color-accent-attention-base: #ffe33a;
$color-accent-negative-base: #ca4039;

$calendar-orange: #febf04;
$calendar-cyan: #01b0ef;
$calendar-yellow: #f9f905;
$calendar-green: #09ae50;
$calendar-white: #9dbce1;

//$calendar-orange: $color-brand-2-base;
//$calendar-cyan: $color-brand-1-light-1;
//$calendar-yellow: $color-accent-attention-base;
//$calendar-green: $color-accent-positive-base;
//$calendar-white: $color-neutral-grey-dark-1;
