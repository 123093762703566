/* VENDOR */

// For more information: https://material.angular.io/guide/theming
@import '../../node_modules/@angular/material/theming';
// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat-core();
// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
@import "base/colors";

// Create the theme object (a Sass map containing all of the palettes).

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.

@import "../../node_modules/normalize.scss/normalize";
@import "../../node_modules/sass-mq/mq";
@import "../../node_modules/angular-calendar/css/angular-calendar.css";

/* BASE */
@import "base/boilerplate";
@import "base/reset";

@import "base/variables";
@import "base/functions";
@import "base/fonts";
@import "base/mixins";
@import "base/typography";
@import "base/common";
@import "base/forms";

/* COMPONENTS */
@import "components/buttons";


