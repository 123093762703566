/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.negative-outcome label {
  color: red;
}

.required-asterisc{
  overflow: visible;
  white-space: nowrap;
  float: left;
  color: $color-brand-1-base;
  font-weight: bold;
}



.main-label{
  float: left;
  max-width: calc(100% - 1rem);
  overflow: hidden;
  text-overflow: ellipsis;
  color: $color-brand-1-base;
  font-weight: bold;
  display: inline;
}

.inline-radio {
  max-width: 50%;
  overflow: hidden;
  padding-top:8px;
  .inline-radio-label {
    float: left;
    overflow: hidden;
    text-overflow: ellipsis;
    color: $color-brand-1-base;
    font-weight: bold;
    display: inline;
    max-width: 100%;
    white-space: nowrap;
  }
  mat-radio-group {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding-top: rem(12);
  }
  mat-radio-button {
    padding-right: rem(16) ;
    padding-bottom: rem(16);
    margin-bottom: rem(0) !important;
  }
  @include mq($until: tablet) {
    max-width: 100%;
  }
}


.form {
  .fieldset {
    &__button {
      @include list-inline();
      justify-items: center;
      @include mq($until: tablet) {
        padding-left: rem(24);
      }
    }

    &__label {
      color: $color-brand-2-base;
      margin-top: rem(40);
      line-height: 1.3;
    }

    &__tip {
      color: $color-brand-2-base;
      margin-top: rem(40);
      line-height: 1.3;
    }
  }

  .mat-form-field {
    @include list-inline();
    //height: rem(48);
    width: 100%;

    input {
      background-color: transparent;
      padding: rem(14) rem(16);
      border: rem(1) solid $color-neutral-grey-base;
      border-radius: rem(8);
      color: $color-neutral-grey-dark-2;

      &::placeholder {
        color: $color-neutral-grey-base;
      }
      &:read-only:not(.fake-readonly) {
        background-color: $color-neutral-grey-light-1;
      }
      &:disabled {
        background-color: $color-neutral-grey-light-1;
      }
    }

    textarea {
      background-color: transparent;
      padding: rem(12) rem(16);
      border: rem(1) solid $color-neutral-grey-base;
      border-radius: rem(8);
      color: $color-neutral-grey-dark-2;
      &:disabled {
        background-color: $color-neutral-grey-light-1;
      }
    }

    .mat-form-field-wrapper {
      padding-bottom: 0;
      width: 100%;

      .mat-form-field-infix {
        padding: 0;
        border-top: none;
      }
    }

    .dialog-opener {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: rem(44);
      border-radius: rem(8);
      opacity: 0;
    }

    &.field-signature {

      .field-signature__container {
        width: 100%;
        display: flex;
        .btn {
          margin-left: rem(16);
          min-width: rem(124);
        }

        @include mq($until: mobileLandscape) {
          display: block;
          input {
            width: 100%;
          }
          .btn {
            width: 100%;
            margin-left: 0;
            margin-top: rem(8);
          }
        }
      }
      .field-signature__fake-input {
        background-color: transparent;
        padding: rem(12) rem(16);
        border: rem(1) solid $color-neutral-grey-base;
        border-radius: rem(8);
        color: $color-neutral-grey-dark-2;
        position: relative;
        width: 100%;
        mat-icon {
          position: absolute;
          right: 0.5rem;
          top: 0.6rem;
          fill: $color-accent-negative-base;
        }
      }
      &.signed {
        .field-signature__fake-input {
          mat-icon {
            fill: $color-accent-positive-base;
          }
        }
      }
    }
  }

  //.mat-form-field-invalid {
  //  input {
  //    border: solid 1px red;
  //  }
  //
  //  mat-error {
  //    color: red;
  //  }
  //}

  .mat-form-field-appearance-legacy .mat-form-field-subscript-wrapper {
    top: rem(80);
    margin-left: rem(8);
    margin-top: 0;
  }

  .mat-form-field-label-wrapper {
    overflow: inherit;
    left: rem(16);
    top: rem(-10);
  }

  .mat-form-field-appearance-legacy .mat-form-field-wrapper {
    padding-top: rem(32);
  }

  .mat-form-field-appearance-legacy .mat-form-field-underline {
    display: none;
  }

  .mat-form-field-appearance-legacy.mat-form-field-can-float {
    &.mat-form-field-should-float .mat-form-field-label {
      //transform: translateY(0);
      transform: translateY(-2.3em) translateX(-0.5em);
      width: 100%;
      color: $color-brand-1-base;
      font-weight: bold;
      //font-size: rem(16);
      //display: none;
    }
  }
  .mat-form-field-appearance-fill.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label {
    //display: none;
    transform: translateY(-2.3em) translateX(-0.5em);
    width: 100%;
    color: $color-brand-1-base;
    font-weight: bold;
  }

  .mat-form-field-appearance-legacy.mat-form-field-can-float {
    &.mat-form-field-should-float .mat-form-field-label:not(.mat-form-field-empty) {
      //transform: translateY(0);
      //font-size: rem(16);
      //color: $color-brand-2-base;
      //width: 100%;
      //display: none;
    }
  }

  .mat-form-field-appearance-legacy.mat-form-field-can-float.mat-focused {
    .mat-form-field-label-wrapper .mat-form-field-label {
      //transform: translateY(0);
      //font-size: rem(16);
      //color: $color-brand-2-base;
      //width: 100%;
      //display: none;
    }
  }

  .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-form-field-autofill-control:-webkit-autofill + .mat-form-field-label-wrapper .mat-form-field-label {
    //transform: translateY(rem(-(32)));
    //font-size: rem(16);
    //color: $color-brand-2-base;
    //width: 100%;
    //display: none;
  }

  .mat-form-field.mat-focused {
    .mat-form-field-required-marker {
      color: $color-brand-2-base;
    }
  }

  .mat-form-field-infix {
    border-top: rem(24);
  }

  .mat-form-field-appearance-legacy .mat-hint {
    color: $color-brand-1-base;
  }

  .mat-button[disabled][disabled] {
    color: $white;
  }

  .mat-checkbox-background, .mat-checkbox-frame {
    border-radius: 0 !important;
  }

  //.mat-checkbox-background {
  //  background: $white;
  //}

  .mat-radio-button.mat-accent.mat-radio-checked .mat-radifo-outer-circle {
    border-color: $color-brand-1-base;
  }

  .mat-radio-button.mat-accent .mat-radio-inner-circle, .mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple, .mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
    background-color: $color-brand-1-base;
  }

  .mat-radio-group {
    .mat-radio-button {
      @include mq($until: tablet) {
        margin: rem(24) 0;
      }
    }
  }

  .mat-checkbox-checked.mat-accent .mat-checkbox-background, .mat-checkbox-indeterminate.mat-accent .mat-checkbox-background {
    background-color: $color-brand-1-base;
  }
  .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element, .mat-checkbox:active:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element {
    background-color: $color-brand-1-base;
  }

  .mat-form-field-appearance-fill .mat-form-field-flex {
    padding: 2rem 0 0 0;
  }

  .mat-form-field-appearance-fill .mat-form-field-label {
    top: 1.79375em;
  }

  .mat-checkbox-layout {
    white-space: normal;
    align-items: flex-start;
  }
  .mat-radio-label {
    white-space: normal;
    align-items: flex-start;
  }
  .mat-radio-label-content {
    padding-top: 2px;
  }
  .mat-checkbox-inner-container {
    margin: rem(4) rem(8) auto auto;
  }

  .mat-form-field.field-date {
    .mat-icon {
      position: absolute;
      right: 0.5rem;
      top: 0.6rem;
      fill: $color-brand-1-base;
    }
  }


  &__fields-container {
  }
  &__row {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    max-width: 1200px;
    margin: 0 auto;
    width: 100% !important;
    //padding: rem(24) 0;
    padding: rem(24) 0 rem(100) 0;
    @include mq($until: wide) {
      padding: rem(16) rem(16) rem(100) rem(16);
    }

  }
  &__tip {
    color: $color-neutral-grey-dark-3;
    font-size: rem(12);
    font-weight: bold;
    margin: rem(16) 0;
  }
  &__alert {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: rem(80) 0;
  }
  &__field {
    flex-basis: calc(100% / 2 - 24px);
    margin-left: rem(40);
    margin-bottom: rem(24);
    position: relative;
    &__dropdown {
      position: absolute;
      top: 40px;
      width: 100%;
      max-height: 100px;
      overflow: scroll;
      background-color: white;
      z-index: 500;
      border:1px solid grey;
      >div {
        padding:8px;
        border-bottom: 1px solid grey;
        cursor: pointer;
      }
    }

    &__half{
      flex-basis: calc(100% / 4 - 40px);
      margin-right: rem(40);
      margin-left: rem(0) !important;
      margin-bottom: rem(24);
    }
    &__full{
      flex-basis: calc(100% - 40px);
    }

    &:first-child, &:nth-child(2n +1) {
      margin-left: 0;
    }

    &:last-child {
      //margin-bottom: rem(100);
    }

    @include mq($until: tablet) {
      flex-basis: 100%;
      margin-left: 0;
    }

    .mat-form-field {
      max-width: 100%;
    }
  }
}

.mat-datepicker-content {
  background-color: $white;
  border: rem(1) solid $color-brand-1-base;
}


.mat-calendar-body-cell-content, .mat-date-range-input-separator {
  color: rgba(0,0,0,.87);
  border-color: transparent;
}
.mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(0,0,0,.38);
}
.cdk-keyboard-focused .mat-calendar-body-active>.mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical), .cdk-program-focused .mat-calendar-body-active>.mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical), .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover>.mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(34,65,140,.3);
}
.mat-calendar-body-in-range:before {
  background: rgba(34,65,140,.2);
}
.mat-calendar-body-range-start:not(.mat-calendar-body-in-comparison-range)::before, .mat-calendar-body-range-start::after, .mat-calendar-body-comparison-start:not(.mat-calendar-body-comparison-bridge-start)::before, .mat-calendar-body-comparison-start::after, .mat-calendar-body-preview-start .mat-calendar-body-cell-preview {
  left: 5%;
  width: 95%;
  border-top-left-radius: 999px;
  border-bottom-left-radius: 999px;
}
.mat-calendar-body-disabled {
  opacity: 0.4;
}

//FILE INPUT

.file-upload {
  margin: rem(24) 0 rem(16) 0;

  input.mat-input-element {
    text-overflow: ellipsis;
    margin-top: rem(3);
  }

  .ngx-mat-file-input {
    button {
      background-color: white;
      // visibility: hidden;
    }
  }
}

//
.error {
  color: red;
  font-size: rem(12);
  margin: 0;
}

//DATA INPUT FIELD

.data-field {
  display: flex;
  align-items: center;
  input {
    width: calc(100%);
    margin-right: rem(8);
  }
  .mat-icon {
    right: 71px !important;
    top: 10px !important;
  }
  button {
    width: rem(48);
    height: 100%;
    padding: rem(11) rem(8);
    display: inline-flex;
    align-items: center;
    justify-content: center;
    .mat-icon {
      position: initial !important;
      fill: $white !important;
    }
  }
}

