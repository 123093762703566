@mixin list-reset {
  list-style: none;
  margin: 0;
  padding: 0;
  li {
    margin: 0;
    padding: 0;
  }
}

@mixin list-inline {
  display: flex;
  align-items: center;
}

@mixin button($color-bg, $color-text, $border: false, $border-radius: true) {
  background-color: $color-bg;
  .btn__label {
    color: $color-text !important;
  }
  .btn__icon {
    svg {
      path {
        fill: $color-text !important;
      }
    }
  }
  @if $border {
    border: 1px solid $color-text;
  } @else {
    border: none;
  }
  @if $border-radius {
    border-radius: 999px;
  } @else {
    border-radius: 0;
  }
}

@mixin cover($posX: center, $posY: center, $color-fallback: $fill-light-green-2) {
  background-size: cover;
  background-position: $posX $posY;
  background-repeat: no-repeat;
  background-color: $color-fallback;
}
