@font-face {
  font-family: Roboto;
  src: url('../../assets/fonts/Roboto-Regular.eot'), /* IE9 Compat Modes */
  url('../../assets/fonts/Roboto-Regular.woff2') format("woff2"),
  url('../../assets/fonts/Roboto-Regular.woff') format("woff"),
  url('../../assets/fonts/Roboto-Regular.ttf') format("truetype");
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: Roboto;
  src: url('../../assets/fonts/Roboto-Bold.eot'), /* IE9 Compat Modes */
  url('../../assets/fonts/Roboto-Bold.woff2') format("woff2"),
  url('../../assets/fonts/Roboto-Bold.woff') format("woff"),
  url('../../assets/fonts/Roboto-Bold.ttf') format("truetype");
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
}


$roboto: 'Roboto', sans-serif;
